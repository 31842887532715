<template>
  <h1>{{$t('pages.map.title')}}</h1>
  <div class="map_filter">
    <aside>
      <Filter @filtered="apply_filter"/>
    </aside>
    <GMapMap
      :center="center"
      :zoom="zoom">
      <GMapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      @click="openMarker(m.id)"
      :icon="markerIcon(m.item)">
       <GMapInfoWindow
        :closeclick="true"
        @closeclick="openMarker(null)"
        :opened="openedMarkerID === m.id">
        <Item :item="m.item"></Item>
      </GMapInfoWindow>
    </GMapMarker>
   </GMapMap>
  </div>
</template>

<script>
import Filter from '../components/Filter.vue'
import Item from '../components/Item.vue'
import { getData } from '../data_loader.js'

export default {
 name: "Map",
 components: {
    Filter,
    Item
  },
  data() {
   return {
     center: { lat: 46.8077145, lng: 8.2032245 },
     zoom: 7.9,
     markers: null,
     openedMarkerID: null
   }
 },
 mounted:  function() {
   // Try to fetch the current position
   if ("geolocation" in navigator) {
     navigator.geolocation.getCurrentPosition((position) => {
       this.center = { lat: position.coords.latitude, lng: position.coords.longitude }
       this.zoom = 7.9
     })
    }

    // Initiate the whole list with all entries
    getData().then(result => {
    this.list = result;
    })

    document.title = this.$i18n.messages[this.$i18n.getLocale()]['pages']['map']['title']
   },
 methods: {
   // Update the filtered list the filter emits filtered
   apply_filter: function(list) {
     this.markers = []
     for (var item of list) {
       this.markers.push(this.marker_params(item));
     }
   },
   marker_params: function(item) {
     return {
       id: item.id,
       item: item,
       position: {
         lat: item.lat,
         lng: item.lng,
       },
       title: item.name
     }
   },
   openMarker: function(id) {
      this.openedMarkerID = id
   },
   markerIcon: function(item) {
     var icon = {
       url: "/images/pins/" + item.type + "_" + item.state + ".png", // url
       scaledSize: { height: 47, width: 33 }
     };
     return icon;
   }
 }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .vue-map-container {
    height: 400px;
  }
}
</style>
