<template>
  <h1>{{$t('imprint')}}</h1>
  <div v-if="$i18n.getLocale() == 'de'">
    Dieses Seite wurde aufgebaut und wird unterhalten vom:
    <address>Verein Legalize it!, Quellenstrasse 25, 8005 Zürich</address>
    Telefon 079 261 16 37, ✉️ <a href="mailto: markus@hanflegal.ch">Mail ans Legalize it!</a>

    <p>
      <a href="https://www.hanflegal.ch/wiki/verein_li/verein">
        Weitere Infos zum Verein Legalize it!
      </a>
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$i18n.messages[this.$i18n.getLocale()]['pages']['imprint']['title']
  }
}
</script>
