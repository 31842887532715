import { createWebHistory, createRouter } from "vue-router";
import Imprint from "@/views/Imprint.vue";
import List from "@/views/List.vue";
import Map from "@/views/Map.vue";
import About from "@/views/About.vue";

const routes = [
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/list",
    name: "List",
    component: List,
  },
  {
    path: "/",
    name: "Map",
    component: Map,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
});

export default router;
