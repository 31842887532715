<template>
  <h1 class="sr-only">{{$t('filter')}}</h1>
  <select v-model="filters.canton" @change="filter">
    <option value="">{{$t('all_cantons')}}</option>
    <option v-for="(canton, index) in sorted_cantons" :key="canton" :value="index">{{canton}}</option>
  </select>
  <fieldset>
    <legend>{{$t('filter_by_type')}}</legend>
    <ul>
      <li v-for="(type, index) in available_types" :key="type">
        <input type="checkbox" v-model="filters.type[index]" :id="'filter_type_' + index" @change="filter"> <label :for="'filter_type_' + index">{{type}}</label>
      </li>
    </ul>
  </fieldset>
  <fieldset>
    <legend>{{$t('filter_by_state')}}</legend>
    <ul>
      <li v-for="(state, index) in available_states" :key="state">
        <input type="checkbox" v-model="filters.state[index]" :id="'filter_state_' + index" @change="filter"> <label :for="'filter_state_' + index">{{state}}</label>
      </li>
    </ul>
  </fieldset>
  {{$t('filtered_size', {filtered: filtered_list.length, all: list.length})}}
</template>

<script>
import { getData } from '../data_loader.js'

export default {

  emits: {
    filtered: (filtered_list) => {
      return Array.isArray(filtered_list)
    }
  },
  data: function () {
   return {
     list: [],
     filtered_list: [],
     filtered_by_type_or_state: [],
     filters: {
       canton: '',
       type: {
         csc: true,
         pharmacy: true,
         city: true,
         others: true
       },
       state: {
         planing: true,
         open: true,
         closing: true,
         unknown: true
       }
     }
   }
  },
  created: function () {
    // Load filter defaults
    var cached_filters = this.cached_filters()
    if(cached_filters == null) {
      this.cache_filters()
    } else {
      this.filters = this.cached_filters()
    }

    // Initiate the whole list with all entries
    getData().then(result => {
      this.list = result;
      this.filter();
   })
 },

 computed: {
    sorted_cantons() {
      var all_cantons = Object.entries(this.$i18n.messages[this.$i18n.getLocale()]['kantons']);
      var cantons = {}

      for (const [key, value] of all_cantons) {
        cantons[key] = value + " (" + this.amount_entries_per_canton(key) + ")"
      }

      return cantons;
    },
    available_types() {
      return this.$i18n.messages[this.$i18n.getLocale()]['type']

    },
    available_states() {
      return this.$i18n.messages[this.$i18n.getLocale()]['state']
    }
  },

  methods: {
    // Get cached filter settings
    cached_filters() {
      return JSON.parse(localStorage.getItem('filters'));
    },

    // Store the settings of filters
    cache_filters() {
      localStorage.setItem('filters', JSON.stringify(this.filters))
    },
    // Calculate the amount of entries per canton already filtered by state or type.
    amount_entries_per_canton(canton) {
      return this.filtered_by_type_or_state.filter((item) => {
        return item.kanton === canton }).length
    },
    filtered_list_sorted_by_name() {
      return this.filtered_list.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },

    filter() {
      // Filter by state or type
      this.filtered_by_type_or_state = this.list.filter((item) => {
        var rejected = false;
        rejected = this.filters.type[item.type] === true
        return rejected && this.filters.state[item.state] === true
      })

      // Filter by canton
      this.filtered_list = this.filtered_by_type_or_state.filter((item) => {
        if (this.filters.canton === "") {
          return true
        }
        return item.kanton === this.filters.canton
      })

      this.$emit('filtered', this.filtered_list_sorted_by_name())

      // Update filter cache
      this.cache_filters()
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0 !important;
  margin: 0 !important;

  li {
    list-style-type: none !important;
  }
}
</style>
