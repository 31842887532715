<template>
  <nav id="li_nav">
    <ul id="li_desktopnav">
      <router-link
        :to="{name: 'Map'}"
        custom
        v-slot="{ href, navigate, isActive }">
        <li :class="[isActive && 'active']">
          <a :href="href" @click="navigate">{{ $t("pages.map.menu_name")}}</a>
        </li>
      </router-link>

      <router-link
        :to="{name: 'List'}"
        custom
        v-slot="{ href, navigate, isActive }">
        <li :class="[isActive && 'active']">
          <a :href="href" @click="navigate">{{ $t("pages.list.menu_name")}}</a>
        </li>
      </router-link>

      <router-link
        :to="{name: 'About'}"
        custom
        v-slot="{ href, navigate, isActive }">
        <li :class="[isActive && 'active']">
          <a :href="href" @click="navigate">{{ $t("pages.about.menu_name")}}</a>
        </li>
      </router-link>
    </ul>
    <div id="li_logo">
      <a href="https://www.hanflegal.ch/"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/logo.svg" width="121" height="50" alt="Logo hanflegal.ch Verein Legalize it!"></a>
    </div>
    <div id="li_burger">
      <label for="burger">
        <svg viewBox="0 0 100 80" width="24" height="24">
          <path
             style="fill:#ffffff"
             d="m 8,0 h 84 c 4.432,0 8,3.568 8,8 v 1 c 0,4.432 -3.568,8 -8,8 H 8 C 3.568,17 0,13.432 0,9 V 8 C 0,3.568 3.568,0 8,0 Z" />
          <path
             style="fill:#ffffff"
             d="m 8,30 h 84 c 4.432,0 8,3.568 8,8 v 1 c 0,4.432 -3.568,8 -8,8 H 8 C 3.568,47 0,43.432 0,39 v -1 c 0,-4.432 3.568,-8 8,-8 z" />
          <path
             style="fill:#ffffff"
             d="m 8,60 h 84 c 4.432,0 8,3.568 8,8 v 1 c 0,4.432 -3.568,8 -8,8 H 8 C 3.568,77 0,73.432 0,69 v -1 c 0,-4.432 3.568,-8 8,-8 z" />
        </svg>
      </label>
    </div>
    <input type="checkbox" id="burger">
    <div id="li_mobilenav">
      <div id="li_mobilenav_top">
        <div id="li_mobilenav_search">
          <!-- TODO: echo mobile_search_form(); -->
        </div>
        <div id="li_burger_close">
          <label for="burger">
            <svg viewBox="0 0 110 110" width="24" height="24">
              <line x1="10" y1="10" x2="100" y2="100" stroke="#75386d" stroke-width="20" />
              <line x1="100" y1="10" x2="10" y2="100" stroke="#75386d" stroke-width="20" />
            </svg>
        </label>
      </div>
    </div>
    <div id="li_mobilenav_content">
      <ul>
        <!-- TODO:    if (count($languagelinks) > 1) {
                echo '<li class="li_lang">';

                foreach($languagelinks as $link) {
                    echo $link;
                }
                echo '</li>';
            } -->
        <!-- TODO: li_top_level_menu(); -->
        <router-link
          :to="{name: 'About'}"
          custom
          v-slot="{ href, navigate, isActive }">
          <li :class="[isActive && 'active']">
            <a :href="href" @click="navigate">{{ $t("pages.home.menu_name")}}</a>
          </li>
        </router-link>

        <router-link
          :to="{name: 'List'}"
          custom
          v-slot="{ href, navigate, isActive }">
          <li :class="[isActive && 'active']">
            <a :href="href" @click="navigate">{{ $t("pages.list.menu_name")}}</a>
          </li>
        </router-link>

        <router-link
          :to="{name: 'Map'}"
          custom
          v-slot="{ href, navigate, isActive }">
          <li :class="[isActive && 'active']">
            <a :href="href" @click="navigate">{{ $t("pages.map.menu_name")}}</a>
          </li>
        </router-link>
      </ul>
      <hr>
      <div class="li_mobile_nav_grid">
        <div>Social Media</div>
        <div>
          <a href="https://www.hanflegal.ch/facebook" aria-label="Facebook"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/facebook-square-brands.svg" width="30" height="30" alt="facebook" title="facebook" loading="lazy"></a>
          <a href="https://www.hanflegal.ch/twitter" aria-label="Twitter"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/twitter-brands.svg" width="30" height="30" alt="Twitter" title="Twitter" loading="lazy"></a>
          <a href="https://www.hanflegal.ch/instagram" aria-label="Instagram"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/instagram-brands.svg" width="30" height="30" alt="Instagram" title="Instagram" loading="lazy"></a>
        </div>

        <div>E-Mail</div>
        <div><a href="mailto:li@hanflegal.ch">li@hanflegal.ch</a></div>

        <div>Telefon, SMS, WhatsApp</div>
        <div><a href="tel:+41795819044">079 581 90 44</a></div>

        <div>Post</div>
        <address>
          Verein Legalize it!<br>
          Quellenstrasse 25<br>
          8005 Zürich<br><br>
          <a href="https://www.hanflegal.ch/wiki/impressum">Impressum</a><br><br>
          <a href="https://www.hanflegal.ch/wiki/cookie_policy">Cookie Policy</a>
        </address>
      </div>
    </div>
  </div>
</nav>
<div id="li_main">
  <div id="li_content">
    <router-view />
  </div>
  <div id="li_highlight">
    Kennst du noch weitere Projekte? Schick uns gern die Details per <a href="mailto: markus@hanflegal.ch">E-Mail</a>!
  </div>
</div>
<div id="li_footer_container">
  <div id="li_footer">
    <div>
      <div class="li_footer_block" id="lispende">
        <div class="li_footer_block_content">
          <strong>Unterstützen / Spende</strong><br>
          <br>
          Unterstütze unsere Arbeit durch eine Spende:<br>
          <br>
          <strong>Banküberweisung</strong><br>
          Kontonummer (IBAN):<br><small><a href="https://www.hanflegal.ch/wiki/mithilfe/spende" style="color: inherit; text-appearance: none">CH02 0900 0000 8709 1354 3</a></small><br>
          <a href="https://www.hanflegal.ch/wiki/mithilfe/spende">Alle Kontodetails</a><br>
          <br>
          Oder scanne diesen QR-Code mit deiner eBanking App (ZKB, Revolut, Postfinance,
          …):
          <br>
          <img src="https://www.hanflegal.ch/wiki/_media/spende-qrbill.png" width="150" height="150" style="margin-top: 1em; margin-bottom: 1em" alt="QR-Code Spende" title="scanne diesen QR-Code mit deiner eBanking App" loading="lazy"><br>
          Oder öffne/teile den <a href="https://www.hanflegal.ch/wiki/_media/spende-qrbill.pdf">QR-Code als PDF-Datei</a> mit deiner eBanking App.
          <br>
          <br>
          <strong>Kreditkarte</strong><br>
          <a href="https://www.hanflegal.ch/wiki/mithilfe/spende"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/visa.svg" width="50" height="32" border="0" style="margin: .25em" alt="VISA" loading="lazy"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/mastercard.svg" width="50" height="32" border="0" style="margin: .25em" alt="MasterCard" loading="lazy"><br>Per Kreditkarte spenden</a>
          <br>
        </div>
      </div>

      <div class="li_footer_block" id="lijoin">
        <div class="li_footer_block_content">
          <strong>Mitglied werden</strong>
          <form action="https://join.hanflegal.ch/signup" method="post">
            <div style="margin: auto; margin-top: 1em; margin-bottom: 1em">
              <div style="display: flex; flex-direction: column" class="signup">
              <div class="tabs">
                <input name="orderkind" type="radio" id="kind_membership" checked="checked" value="kind_membership">
                <label class="tablabel" for="kind_membership"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/user-friends-solid.svg" width="16" height="16" alt="Symbol Mitgliedschaft" loading="lazy"><br>Privat</label>
                <div class="panel"></div>
                <div class="submit">
                  <input type="submit" value="Für 50 CHF/Jahr Mitglied werden!">
                </div>

                <input name="orderkind" type="radio" id="kind_company" value="kind_company">
                <label class="tablabel" for="kind_company"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/building-regular.svg" width="16" height="16" alt="Symbol Firma" loading="lazy"><br>Firma</label>
                <div class="panel" style="order: 97">
                  <div class="li_fields_company_container">
                <label for="email">Firma</label>
                <input type="text" name="company" placeholder="Firma">
                  </div>
                </div>
                <div class="submit">
                  <input type="submit" value="Für 200 CHF/Jahr Firmen-Mitglied werden!">
                </div>

                <input name="orderkind" type="radio" id="kind_aboli" value="kind_aboli">
                <label class="tablabel" for="kind_aboli"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/newspaper-regular.svg" width="16" height="16" alt="Symbol Magazin" loading="lazy"><br>Abo</label>
                <div class="panel">
                  <div class="li_fields_aboli_container">
                <div>
                  <input type="checkbox" name="sample" id="sample">
                  <label for="sample">Nur ein Probeexemplar gratis bestellen, kein Abo</label>
                </div>
              </div>
            </div>
            <div class="submit">
              <input type="submit" value="Für 20 CHF/Jahr Magazin abonnieren!">
            </div>

            <input name="orderkind" type="radio" id="kind_sh" value="kind_sh">
            <label class="tablabel" for="kind_sh"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/balance-scale-solid.svg" width="16" height="16" alt="Symbol Rechtsauskünfte" loading="lazy"><br>Broschüre</label>
            <div class="panel">
            </div>
            <div class="submit">
              <input type="submit" value="Für 17 CHF Rechtshilfebroschüre bestellen!">
            </div>

            <div class="li_fields_placeholder_top">&nbsp;</div>

            <div class="li_fields_common">

              <label for="email">E-Mail</label>
              <input type="email" name="email" id="email" autocomplete="email" placeholder="urs@mueller.example" required value="">

              <label for="givenname">Vorname</label>
              <input type="text" name="givenname" id="givenname" autocomplete="given-name" placeholder="Urs" required maxlength="70" value="">

              <label for="familyname">Nachname</label>
              <input type="text" name="familyname" id="familyname" autocomplete="family-name" placeholder="Müller" required maxlength="70" value="">

              <label for="address1">Adresse</label>
              <input type="text" name="address1" id="address1" autocomplete="address-line1" placeholder="Quellenstrasse 25" required maxlength="70" value="">

              <label for="postal">PLZ</label>
              <div style="margin-left: 5px">
            CH-<input type="text" pattern="[0-9]*" name="postal" id="postal" autocomplete="postal-code" style="display: inline-block; width: 40%" placeholder="8005" required maxlength="70" value="">
              </div>

              <label for="address2">Stadt</label>
              <input type="text" name="address2" id="address2" autocomplete="address-level2" placeholder="Zürich" required maxlength="70" value="">

              <label for="tel">Telefon (optional)</label>
              <input type="text" name="tel" id="tel" autocomplete="tel" placeholder="076 123 45 67" maxlength="70" value="">

            </div> <!-- li_fields_common -->

            <div class="li_fields_placeholder_bottom">&nbsp;</div>
          </div> <!-- tabs -->

          </div>
        </div>
      </form>
    </div>
    </div>
    </div>
      <div>
        <div class="li_footer_block" id="licontact">
          <div class="li_footer_block_content">
            <div>
              <strong>Kontakt</strong><br><br>
              <em>E-Mail</em><br>
              <a href="mailto:li@hanflegal.ch">li@hanflegal.ch</a><br>
            </div>

            <div>
              <em>Post</em><br>
              Verein Legalize it!<br>
              Quellenstrasse 25<br>
              8005 Zürich<br>
            </div>

            <div>
              <em>Telefon, SMS, WhatsApp</em><br>
              <a href="tel:+41795819044">079 581 90 44</a><br>
            </div>

            <div>
              <em>Threema</em><br>
              <a href="https://www.hanflegal.ch/threema">Threema ID 7NH65RBY</a>
            </div>

            <div id="li_footer_imprint_social">
              <p>
                Nichts mehr verpassen! Folge uns auf Social Media:
              </p>
              <div class="li_social_media_grid">
                <div>
                  <a href="https://www.hanflegal.ch/facebook" aria-label="Facebook"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/facebook-square-brands.svg" width="30" height="30" alt="facebook" title="facebook" loading="lazy"></a>
                </div>
                <div>
                  <a href="https://www.hanflegal.ch/facebook">Facebook</a>
                </div>
                <div>
                  <a href="https://www.hanflegal.ch/twitter" aria-label="Twitter"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/twitter-brands.svg" width="30" height="30" alt="Twitter" title="Twitter" loading="lazy"></a>
                </div>
                <div>
                  <a href="https://www.hanflegal.ch/twitter">Twitter</a>
                </div>
                <div>
                  <a href="https://www.hanflegal.ch/instagram" aria-label="Instagram"><img src="https://www.hanflegal.ch/wiki/lib/tpl/hanflegal/images/instagram-brands.svg" width="30" height="30" alt="Instagram" title="Instagram" loading="lazy"></a>
                </div>
                <div>
                  <a href="https://www.hanflegal.ch/instagram">Instagram</a>
                </div>
              </div>
            </div>
            <div id="li_footer_imprint">
              <ul>
               <li><a href="https://www.hanflegal.ch/wiki/impressum">Impressum</a></li>
               <li><a href="https://www.hanflegal.ch/wiki/cookie_policy">Cookie Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    document.title = this.$i18n.messages[this.$i18n.getLocale()]['default_page_title']
  }
}
</script>
