import { createI18n } from "vue3-i18n"

const messages = {
   de: {
     default_page_title: 'Index Cannabis Pilotprojekte Schweiz',
     pages: {
       about: {
         title: "Karte Cannabis Pilotprojekte Schweiz - Über die Pilotversuche",
         menu_name: "Pilotversuche"
       },
       imprint: {
         title: "Karte Cannabis Pilotprojekte Schweiz - Impressum",
         menu_name: "Impressum"
       },
       map: {
         title: "Karte Cannabis Pilotprojekte Schweiz",
         menu_name: "Karte"
       },
       list: {
         title: "Karte Cannabis Pilotprojekte Schweiz - Liste",
         menu_name: "Liste"
       }
     },
     filter: "Filter",
     filter_by_type: "Bezugsmodell",
     filter_by_state: "Status",
     filtered_size: "Zeige {filtered} von {all} Einträgen",
     main_nav: "Hauptnavigation",
     comming_soon: "Comming Soon!",
     type: {
       csc: "🎉 Cannabis Social Club",
       pharmacy: "💊 Apotheke",
       city: "🏛 Städteprojekt",
others: "Sonstiges"
     },
     state: {
       planing: "🟡 in Planung",
       open: "🟢 Offen",
       closing: "⛔ abschliessend (keine neuen Mitglieder werden aufgenommen)",
       unknown: "❓ Status nicht bekannt"
     },
     no_result_for_filter: "Keine Resultate mit diesen Kriterien.",
     all_cantons: "Alle Kantone",
     kantons: {
       ag: "Aargau",
       ar: "Appenzell Ausserrhoden",
       ai: "Appenzell Innerrhoden",
       bl: "Basel-Landschaft",
       bs: "Basel-Stadt",
       be: "Bern",
       fr: "Freiburg",
       ge: "Genf",
       gl: "Glarus",
       gr: "Graubünden",
       ju: "Jura",
       lu: "Luzern",
       ne: "Neuenburg",
       nw: "Nidwalden",
       ow: "Obwalden",
       sh: "Schaffhausen",
       sz: "Schwyz",
       so: "Solothurn",
       sg: "St. Gallen",
       ti: "Tessin",
       tg: "Thurgau",
       ur: "Uri",
       vd: "Waadt",
       vs: "Wallis",
       zg: "Zug",
       zh: "Zürich"
     }
   }
}

const i18n = createI18n({
   locale: "de",
   messages: messages,
})

export default i18n;
