<template>
  <h2>{{item.name}}</h2>
  {{$t("type." + item.type)}}, {{$t("state." + item.state)}}
  <p v-html="item.description"></p>
  <div class="address">
    <div>
      <img v-if="item.kanton" :src="`images/flags/${item.kanton}.png`" :alt="$t('kantons.' + item.kanton)">
      <address v-if="item.address">{{item.address}}</address>
    </div>
  </div>
  <a class="link" :href="item.url" v-if="item.url">Website von «{{item.name}}»</a>
  <a class="link" :href="item.registration_url" v-if="item.registration_url">Registration</a>
</template>

<script>
  export default {
     props: {
       item: {
         type: Object
       }
     }
  }
</script>

<style lang="scss">
.address {
  display: flex;
  align-items: center;

  img {
    display: inline-block;
    margin-right: 1.25rem;
    width: 1.25rem;
    vertical-align: middle;
  }

  address {
    display: inline-block;
  }
}

.address > div {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.link {
  display: block;
}
</style>
