<template>
  <h1>{{$t('pages.about.title')}}</h1>

  <p>
    Um eine sachliche Grundlage für eine Diskussion zur Weiterentwicklung der Cannabispolitik in der Schweiz zu schaffen, sind verschiedene wissenschaftliche Studien geplant oder bereits am laufen. Der <a href="https://www.bag.admin.ch/dam/bag/de/dokumente/npp/cannabis/betm-pv-entwurf/aenderungserlass_art_8a_betmg.pdf.download.pdf/fedlex-data-admin-ch-eli-fga-2020-2002-de-pdf-x.pdf">Artikel 8a des BetmG</a> bildet dabei die Gesetzliche Grundlage für die Durchführung dieser örtlich und zeitlich begrenzten, wissenschaftlichen Pilotversuche.
  </p>
  <p>
    Zentral dabei ist es schweizer Bürgern im Rahmen solcher Studien die Möglichkeit zu bieten, <b>legal kontrolliertes Cannabis aus biologischem Anbau zu beziehen und dies im privaten Rahmen zu konsumieren.</b>
  </p>
  <div class="listbutton">
    <router-link :to="{name: 'List'}" class="action_button">Liste der aktuellen Pilotversuche</router-link>
  </div>
  <h2>Teilnahmebedingungen</h2>
  <p>
    Die Teilnahmebedingungen unterscheiden sich von Studie zu Studie. Oft gelten regionale Rahmenbedingugen. Folgende Teilnahmebedingungen gelten jedoch gesamtschweizerisch.
  </p>
  <div class="allowed bg-green-100 p-3 rounded-lg mt-2">
     An Pilotversuchen können Personen teilnehmen, die:

    <ul>
      <li>nachweislich bereits Cannabis konsumieren;</li>
      <li>ihren Wohnsitz in dem Kanton haben, in dem der Pilotversuch durchgeführt wird; und</li>
      <li>den Bedingungen der wissenschaftlichen Studie zustimmen und ihre schriftliche Einwilligung zur Teilnahme geben.</li>
    </ul>
  </div>

  <div class="excluded bg-red-100 p-3 rounded-lg mt-2">
     Ausgeschlossen ist die Teilnahme von Personen, die:

    <ul>
      <li>minderjährig sind;</li>
      <li>urteilsunfähig sind;</li>
      <li>schwanger sind oder stillen;</li>
      <li>an einer ärztlich diagnostizierten Krankheit leiden, bei welcher Cannabiskon- sum kontraindiziert ist.</li>
    </ul>
  </div>

  <h2>Verschiedene Bezugsmodelle</h2>
  <dl>
    <dd>{{$t('type.pharmacy')}}</dd>
    <dt>
      <p>Teilnehmende kaufen bei diesem Modell das Cannabis direkt in der Apotheke. Eine Apotheke ist hier jedoch kein Cannabis-Fachgeschäft oder «Dispensary», wie man es aus einigen Länder kennt, in denen Cannabis bereits legal erworben werden kann. Es sind bereits existierende Apotheken, die Medikamente auf Rezept, Hygiene-Artikel etc. verkaufen und nun einfach auch Cannabis. Das Verkaufspersonal wird geschult und kann steht einem in verschiedenen Fragen mit Rat beiseite. Die Mitarbeiter haben aber selbst meist keine Erfahrung mit den Wirkstoffen und deren Wirkung.</p>
      <p>
      Apotheken sind für Teilnehmende interessant, die genau wissen, was sie wollen und zu Hause für sich alleine Konsumieren wollen.</p>
    </dt>
    <dd>{{$t('type.csc')}}</dd>
    <dt>
      <p>Cannabis Social Clubs sind nicht gewinnorientierte Vereine, bestehend aus Konsumierenden, die zusammen Cannabis anbauen, verarbeiten und konsumieren. Es gibt ein Vereinslokal, in dem das Cannabis bezogen und konsumiert werden kann. Der Fokus dieses Models liegt auf dem sozialen Austausch und dem Treffen. Neben dem reinen Konsum können sich Gleichgesinnten auszutauschen und beispielsweise Workshops oder andere Events organisieren.</p>
      <p>Teilnehmende, die den sozialen Austausch suchen, gerne Experimentieren und neue Konsumformen kennenlernen wollen, sind bei diesem Modell angesprochen.</p>
    </dt>
    <dd>{{$t('type.others')}}</dd>
    <dt>
      <p>Zusätzlich gibt es weitere unterschiedliche Modelle, die unter all den Pilotversuchen ausnahmen darstellen. Beispielsweise die Abgabe im Rahmen einer Beratung im Drogeninformationszentrum.</p>
    </dt>
  </dl>
</template>

<script>
export default {
  mounted() {
    document.title = this.$i18n.messages[this.$i18n.getLocale()]['pages']['about']['title']
  }
}
</script>

<style scoped>
div.listbutton {
  padding-top: 1rem;
  justify-content: center;
  display: flex;
}

div.allowed, div.excluded {
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

div.allowed {
  background-color: #d1fae5;
}

div.excluded {
  background-color: #fee2e2;
}

dd {
  margin-left: 0;
}
</style>
