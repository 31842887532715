import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import './assets/tailwind.css'
import './assets/application.scss'
import i18n from "./i18n"
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)

// Enable devtools
app.config.devtools = true

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDDqvG4rthBPfpbuwjEOWV45yWgrdRlEVY'
  },
  autobindAllEvents: true,
})

app.use(i18n).use(router).mount('#app')
