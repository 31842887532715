<template>
  <h1>{{$t('pages.list.title')}}</h1>
  <div class="map_filter">
    <aside>
      <Filter @filtered="apply_filter"/>
    </aside>
    <div>
      <p v-if="filtered_list.length === 0" class="py-5 text-lg">{{$t('no_result_for_filter')}}</p>

      <div v-for="item in filtered_list" :key="item.id" class="list">
        <Item :item="item"></Item>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '../components/Item.vue'
import Filter from '../components/Filter.vue'

export default {
  name: 'List',
  components: {
     Item,
     Filter
   },
   data: function () {
     return {
      filtered_list: [] // Filtered list that will displayed.
    }
  },
  methods: {
    // Update the filtered list the filter emits filtered
    apply_filter: function(value) {
      this.filtered_list = value
    }
  },
  mounted() {
    document.title = this.$i18n.messages[this.$i18n.getLocale()]['pages']['list']['title']
  }
}
</script>

<style lang="scss">
.list {
  border: 2px solid #75386d;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding: 1rem;

  h2 {
    @apply font-bold text-lg;
  }
}
</style>
